const patientConstants = {
  GET_NEW_REQUESTS_REQUEST: 'GET_NEW_REQUESTS_REQUEST',
  GET_NEW_REQUESTS_SUCCESS: 'GET_NEW_REQUESTS_SUCCESS',
  GET_NEW_REQUESTS_FAILURE: 'GET_NEW_REQUESTS_FAILURE',
  GET_BOOKED_REQUESTS_REQUEST: 'GET_BOOKED_REQUESTS_REQUEST',
  GET_BOOKED_REQUESTS_SUCCESS: 'GET_BOOKED_REQUESTS_SUCCESS',
  GET_BOOKED_REQUESTS_FAILURE: 'GET_BOOKED_REQUESTS_FAILURE',
  GET_CANCELED_REQUESTS_REQUEST: 'GET_CANCELED_REQUESTS_REQUEST',
  GET_CANCELED_REQUESTS_SUCCESS: 'GET_CANCELED_REQUESTS_SUCCESS',
  GET_CANCELED_REQUESTS_FAILURE: 'GET_CANCELED_REQUESTS_FAILURE',
  GET_BOOKED_AND_CANCELED_REQUEST: 'GET_BOOKED_AND_CANCELED_REQUEST',
  GET_BOOKED_AND_CANCELED_SUCCESS: 'GET_BOOKED_AND_CANCELED_SUCCESS',
  GET_BOOKED_AND_CANCELED_FAILURE: 'GET_BOOKED_AND_CANCELED_FAILURE',
  GET_REQUEST_REQUEST: 'GET_REQUEST_REQUEST',
  GET_REQUEST_SUCCESS: 'GET_REQUEST_SUCCESS',
  GET_REQUEST_FAILURE: 'GET_REQUEST_FAILURE',
  REJECT_REQUEST_REQUEST: 'REJECT_REQUEST_REQUEST',
  REJECT_REQUEST_SUCCESS: 'REJECT_REQUEST_SUCCESS',
  REJECT_REQUEST_FAILURE: 'REJECT_REQUEST_FAILURE',
  SEND_QUOTATION_REQUEST: 'SEND_QUOTATION_REQUEST',
  SEND_QUOTATION_SUCCESS: 'SEND_QUOTATION_SUCCESS',
  SEND_QUOTATION_FAILURE: 'SEND_QUOTATION_FAILURE',
  GENERATE_SHARE_TOKEN_REQUEST: 'GENERATE_SHARE_TOKEN_REQUEST',
  GENERATE_SHARE_TOKEN_SUCCESS: 'GENERATE_SHARE_TOKEN_SUCCESS',
  GENERATE_SHARE_TOKEN_FAILURE: 'GENERATE_SHARE_TOKEN_FAILURE',
  GET_MEDICAL_INFORMATION_REQUEST: 'GET_MEDICAL_INFORMATION_REQUEST',
  GET_MEDICAL_INFORMATION_SUCCESS: 'GET_MEDICAL_INFORMATION_SUCCESS',
  GET_MEDICAL_INFORMATION_FAILURE: 'GET_MEDICAL_INFORMATION_FAILURE',
  GET_SENDED_QUOTATION_REQUEST: 'GET_SENDED_QUOTATION_REQUEST',
  GET_SENDED_QUOTATION_SUCCESS: 'GET_SENDED_QUOTATION_SUCCESS',
  GET_SENDED_QUOTATION_FAILURE: 'GET_SENDED_QUOTATION_FAILURE',
  CLEAR_DATA: 'CLEAR_DATA',
};

export default patientConstants;
